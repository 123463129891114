{
  "APP": {
    "SINGOUT": "Sair",
    "PROFILE": "Perfil",
    "SETTINGS": "Configurações",
    "CHANGE_PASSWORD": "Alterar senha",
    "MODAL": {
      "SELECT": "Selecionar companhia",
      "ALLOTMENT": "Selecionar loteamento"
    },
    "TITLE": {
      "LOGIN": " - Login",
      "SINGUP": " - SingUp",
      "SYSTEM": "R2 Disponibilidade"
    }
  },
  "PAGINATION": {
    "LAST": "Último",
    "FIRST": "Primeiro",
    "NEXT": "Próximo »",
    "PREV": "« Anterior"
  },
  "COMMISSIONS": {
    "INDEX": "Comissões",
    "VIEW": "Visualizar Comissão"
  },
  "ACTIONS": {
    "PRINT_PDF_CLIENT": "Exportar Cliente em PDF",
    "NEXT": "Próximo",
    "ATTACH_DOCUMENT": "Anexar documentos",
    "NEW": "Novo",
    "SAVE": "Salvar",
    "BACK": "Voltar",
    "SELECT": "Selecionar",
    "CLEAR": "Limpar",
    "CANCEL": "Cancelar",
    "EDIT": "Editar",
    "DUPLICATE": "Duplicar",
    "LABEL": "Ações",
    "CLOSE": "Fechar",
    "COPY_LINK_PUBLIC_CLIENT": "Copiar link",
    "MAP": "Mapa",
    "RENEW": "Renovar",
    "FILTER": "Filtrar",
    "DELETE": "Excluir",
    "VIEW": "Visualizar",
    "UPDATE": "Atualizar",
    "CONFIRM": "Confirmar",
    "HISTORIC": "Histórico",
    "REGISTER": "Cadastrar",
    "CHANGE_TIME": "Alterar Tempo Máximo",
    "SEND_DOCUMENT": "Enviar Documento",
    "INFO": "Informações"
  },
  "PROPOSAL": {
    "STATUS_ASSIGN": "Status Assinatura",
    "IS_IMPRESS": "Já Impressa",
    "SOLICIT_ASSIGN_MANUAL": "Anexar Proposta Assinada",
    "DELETE_ASSIGN_MANUAL": "Deletar Anexo de Proposta Assinada",
    "SOLICIT_ASSIGN_MANUAL_SUCESS": "Proposta Anexada",
    "DATE_CREATE": "Data de Criação",
    "VIEW_DOC_ALREADY_SIGN": "Ver Assinado",
    "VIEW_DOC_ORIGINAL": "Ver Original",
    "CANCELED": "Cancelados",
    "DEADLINE": "Expiração",
    "ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE": "Tem certeza que deseja enviar solicitação de assinatura via whatsapp para esse usuário?",
    "SOLICIT_CLICKSIGN": "Solicitar Assinatura Digital",
    "CANCEL_CLICKSIGN": "Cancelar Assinatura Digital",
    "VIEW_CLICKSIGN": "Visualizar Assinatura",
    "DOCUMENTS": "Documentos",
    "NONE_DOCUMENT_FOUND": "Nenhum documento encontrado!",
    "PARCEL_CONFIG": {
      "TYPE": "Tipo",
      "VALUE": "Valor",
      "PERIODICITY": "Periodicidade",
      "NUMBER_PARCELS": "Nº Parcelas"
    },
    "EDIT": "Editar",
    "EDIT_PROPOSAL": "Editar Proposta",
    "INFO": "Info.",
    "CLIENT": "Clientes",
    "END_DATE": "Data de Expiração",
    "VIEW": "Visualizar Proposta",
    "REJECTE_SUCCESS": "Proposta Rejeitada com Sucesso!",
    "APROVE_SUCCESS": "Proposta Aprovada com Sucesso!",
    "EXPIRED": "Expiração",
    "REJECT": "Rejeitar",
    "APROVE": "Aprovar",
    "BLOCK": "Quadra",
    "REALTOR": "Corretor",
    "LOT": "Lote",
    "NAME": "Nome",
    "ALLOTMENTS": "Loteamentos Vinculados",
    "REPRINT": "Reimprimir",
    "STATUS": "Status",
    "INDEX": "Propostas",
    "VIEW_PROPOSAL": "Visualizar Propostas",
    "PROPOSAL_NOT_FOUND": "Nenhuma proposta foi encontrada",
    "INDEX_TEMPLATE": "Templates de proposta",
    "DUPLICATED": "Duplicar",
    "EDIT_TEMPLATE": "Editar Template",
    "TEMPLATE_NAME": "Nome",
    "PROPOSALS": "Propostas",
    "PROPOSAL": "Proposta",
    "TEMPLATE": "Template",
    "ADD_TEMPLATE": "Criar Template",
    "VIEW_TEMPLATE": "Visualizar Template",
    "TEMPLATE_CREATED": "Template criado com sucesso!",
    "UPDATED_TEMPLATE": "Template atualizado com sucesso!",
    "CREATE": "Criar Proposta"
  },
  "GROUP": {
    "COPY_MAP_LINK": "Copiar link Mapas",
    "COPY_PUBLIC_LINK": "Copiar link área publica",
    "RESERVED_SUCCESS": "Reserva criada com sucesso!",
    "ACESS_MAP": "Ver Mapa",
    "ALLOTMENTS": "Loteamentos",
    "NONE_ALLOTMENT_FOUD": "Não Existem Loteamentos Habilitados",
    "EDIT_GROUP": "Editar Grupo",
    "NAME": "Nome",
    "VIEW_LOT": "Visualizar propriedade",
    "SITUATION": "Situação",
    "ADD_NEW_GROUP": "Adicionar novo grupo",
    "VIEW_GROUP": "Visualizar Grupo",
    "NONE_FOUND": "Nenhum grupo foi encontrado"
  },
  "CONFIG_CLICK_SIGN": {
    "INDEX_TITLE": "Configurações de Assinatura Eletrônica",
    "RESPONSIBLE": "Responsável pela Empresa",
    "CREATE": "Criar Configuração",
    "EDIT": "Editar Configuração",
    "VIEW": "Visualizar Configuração",
    "NONE_FOUND": "Nenhuma Configuração foi cadastrada",
    "DEADLINE_TO_SIGNATURE": "Dias para expiração",
    "SELFIE_ENABLED": "Exigir Selfie com Documento",
    "HANDWIRITTEN_ENABLED": "Exigir Assinatura na Tela",
    "OFFICIAL_DOCUMENT_ENABLED": "Exigir Fotos dos Documentos Oficiais",
    "LIVENESS_ENABLED": "Habilitar Prova de Vida",
    "FACIAL_BIOMETRICS_ENABLED": "Habilitar Biometria Facial",
    "REALTOR_SIGN": "Exigir Assinatura do Corretor"
  },
  "CONFIG_SIMULATOR": {
    "SIMULATOR_CONFIG_RESUMED": "Calc. de Financiamento",
    "INDEX_TITLE": "Calculadora de Financiamento",
    "NEW_CONFIG": "Nova Configuração de Financiamento",
    "NONE_FOUND_SIMULATOR_CONFIG": "Nenhuma Configuração de Financiamento Encontrada",
    "EDIT_CONFIG": "Editar Configuração de Financiamento",
    "VIEW_CONFIG": "Visualizar Configuração de Financiamento",
    "VIEW_CONFIG_DEFAULT": "Visualizar Configuração Default de Financiamento",
    "NAME": "Nome da Configuração",
    "ADVANCE_TERMS": "Juros Antes da 1ª Parcela",
    "INTEREST_FROM": "Juros a Partir da Parcela:",
    "CUSTOM_INSTALLMENT": "Parcelas Customizadas",
    "DELETED_CONFIG_SIMULATOR": "Configuração Excluída com Sucesso",
    "APPLY_DIZIMA_FIRST_INSTALLMENT": "Dif. de Dízima na 1ª Parcela",
    "INTERMEDIATE_REVERSE": "Juros Integrados (Inter.)",
    "HIDE_FORMULA_NAME": "Ocultar Nome da Fórmula",
    "FORMULA": "Fórmula",
    "LABEL": "Label",
    "COLOR": "Cor",
    "USE_INSTALLMENT_TYPE": "Usar",
    "ENTRANCE_FIXED": "Entrada em Valor Fixo",
    "INTEREST": "Juros",
    "PAYMENT_DEFAULT_FORM": "Forma de Pagamento Padrão",
    "PERIODICITY": "Periodicidade",
    "REDUCE_FROM_REGULAR": "Abater do Saldo Devedor",
    "SHOW_TOTAL_INTEREST_VALUE": "Exibir Valor Total de Juros",
    "DESCRIPTION": "Descrição",
    "NO_ALLOTMENT_LINKED": "Nenhum loteamento vinculado!",
    "MIN_QUANTITY_INSTALLMENT": "Quant. Mín. Parcelas",
    "MAX_QUANTITY_INSTALLMENT": "Quant. Máx. Parcelas",
    "CHOOSE_DAYS_TO_HABILITY_PAYMENTS": "Marque os dias do mês que podem ser selecionados para pagamentos:",
    "NOT_POSSIBLE_EDIT_DEFAULT_SIMULATOR_CONFIG": "Não é possível editar a configuração padrão do simulador!"
  },
  "ATTRIBUTES": {
    "INDEX": "Personalização de Campos"
  },
  "GRAPHICS": {
    "RESERVES_CONFIRMED_IMOBI_VS": "Reservas Confirmadas vs. Quantidade de Reservas por Imobiliária",
    "RESERVES_CONFIRMED_BROKER_VS": "Reservas Confirmadas vs. Quantidade de Reservas por Corretor",
    "PROGRESS_RESERVE_BY_ALOTMENT": "Progresso de Reservas por Loteamento"
  },
  "ASSIGN": {
    "NOTIFY_PENDING": "Notificar Pendentes",
    "REALLY_NOTIFY_PENDING": "Tem certeza que deseja enviar solicitação de assinatura para todos os usuários pendentes",
    "FINALIZED": "Assinado",
    "CANCELED": "Cancelado",
    "EXPIRED": "Expirado",
    "RESUM_ASSIGN": "Resumo de Assinaturas {allotmentName}",
    "ASSIGNS": "Assinaturas",
    "NONE_FOUND_ADM_PENDING": "Não Existem Assinaturas Pendentes",
    "NONE_FOUND": "Não Existem Assinaturas",
    "NONE_FOUND_CANCELED": "Não Existem Assinaturas Canceladas",
    "NONE_FOUND_EXPIRED": "Não Existem Assinaturas Expiradas",
    "NONE_FOUND_FINALIZED": "Não Existem Assinaturas Finalizadas",
    "NONE_FOUND_AWAITING": "Não Existem Assinaturas em Aguardando",
    "WAITING": "Aguardando",
    "DEADLINE": "Expiração",
    "VIEW_DOC_ORIGINAL": "Ver Original"
  },
  "NAV": {
    "ASSIGN": "Assinaturas",
    "ASSIGN_PENDING_ADM": "Pendentes Administração",
    "PROGRESS_RESERVE_BY_ALOTMENT": "Progresso de Reservas por Loteamento",
    "ATTRIBUTES_VALIDATION": "Personalizar Campos",
    "DRIVE_FILE_TYPE": "Tipos de Anexo",
    "COMMISSION": "Comissões",
    "CLICK_SIGN": "Assinatura Eletrônica",
    "GROUPS": "Grupos Públicos",
    "PROPOSAL": "Propostas",
    "TEMPLATES": "Templates de Proposta",
    "LOT": "Lotes",
    "HOME": "Home",
    "READJUSTMENT": "Reajustes",
    "USER": "Usuário",
    "CLIENT": "Cliente",
    "CLIENTS": "Pessoas",
    "RESERVE": "Reservas",
    "COMPANY": "Companhia",
    "DASHBOARD": "Dashboard",
    "CONFIG": "Configurações",
    "DOCUMENTS": "Documentos",
    "ALLOTMENT": "Loteamentos",
    "SITUATIONS": "Situações de Lote",
    "RESERVE_TYPES": "Tipos de Reserva",
    "PROPERTY_TYPES": "Tipos de Lote",
    "PAYMENT_METHODS": "Formas de Pagamento",
    "MARITAL_STATUSES": "Estados Civis"
  },
  "PUBLIC_AREA": {
    "INDEX": "Propriedades",
    "FILTERS": "Filtros",
    "CITY": "Cidade",
    "ALLOTMENT": "Loteamento",
    "BLOCK": "Quadra",
    "LOT": "Lote",
    "METRO2": "Metragem m²",
    "VALUE_R$": "Valor R$",
    "NOT_FOUND": "Nenhum registro foi encontrado"
  },
  "ALLOTMENT": {
    "GESTOR": "Gestor",
    "RESERVE_EDITED": "Reserva Editada com Sucesso",
    "ID_BD": "ID configuração BD ('-1' quando não há R2Loteamentos)",
    "BROKER": "Corretor",
    "DOWNLOAD_FILE_EXEMPLE": "Baixar arquivo p/ Imp. Corretores",
    "TEMPLATE_PROPOSAL": "Modelo de proposta",
    "RESERVE_HAS_CLIENT_WITHOUT_EMAIL": "Reserva possui clientes sem email",
    "READJUSTMENT_VALUE": "Reajustar valor",
    "USER_ALLOTMENT_ACTIVED": "Usuário ativado com sucesso!",
    "USER_ALLOTMENT_DESACTIVED": "Usuário desativado com sucesso!",
    "USER_CREATE_IN_ALLOTMENT": "Acesso concedido com sucesso!",
    "REALLY_ACCESS_THIS_USER": "Realmente deseja conceder acesso à este usuário",
    "EMAIL": "E-mail",
    "USER": "Usuário",
    "BROKERS": "Corretores",
    "CITY": "Cidade",
    "NEXT": "Próximo",
    "STATE": "Estado",
    "ACTIVE": "Ativo",
    "NOTIFY": "Receber Notificações",
    "GENERAL": "Geral",
    "FORMULA": "Fórmula",
    "MAP_URL": "URL Mapa",
    "PREVIOUS": "Anterior",
    "LATITUDE": "Latitude",
    "LONGITUDE": "Longitude",
    "LOCATION": "Localização",
    "FINANCIAL": "Financeiro",
    "NAME": "Nome do Loteamento",
    "INDEX_TITLE": "Loteamentos",
    "FEES": "Juros de Financiamento",
    "READJUSTMENTS": "Índice de Reajuste",
    "USE_NEW_CALC": "Usar Nova Calculadora",
    "INITIAL_PAYMENT": "Valor Mínimo de Entrada",
    "BROKERAGE_VALUE": "Valor base para comissão/corretagem",
    "MAX_DURATION_RESERVES": "Duração Máx. Reservas",
    "URL_SITE_SOCIAL_MIDIA": "URL Site / Rede Social / Outros",
    "CHARACTERISTICS_ALLOTMENT": "Características do Loteamento",
    "MAX_NUMBER_RESERVE_PER_REALTOR": "Máximo de reserva por corretor",
    "REQUIRED_DATA_EMAIL_TO_CREATE_RESERVE": "Obrigatoriedade do email do cliente na reserva",
    "READJUST_PRICE_PER_METER": "Preço por Metro (reajuste ao rescindir/cancelar)",
    "MODAL_TITLE": {
      "EDIT": "Editar Loteamento",
      "ADD": "Adicionar Loteamento"
    },
    "MSG": {
      "DISPLAY_MAP_ALLOTMENT_IN_MARKETING": "Exibir Mapa do Loteamento na Área Pública",
      "ALLOW_MARKETING_OF_ALLOTMENT": "Exibir Loteamento na Área Pública",
      "SHOW_VALUES_OF_LOTS_IN_PUBLIC_AND_MAP": "Exibir Valor do Lote e Valor do M² na Área Pública e Mapa",
      "DISPLAY_FINANCING_SIMULATOR_FOR_MARKETING": "Exibir Simulador de Financiamento na Área Pública ",
      "ACTION_CALCULATE_FINANCING_ON_MAP": "Permitir calculadora financeira no mapa",
      "ACTION_RESERVE_LOT_ON_MAP": "Permitir reservar lote no mapa e no link público",
      "ACTION_VIEW_LOT_DATA_ON_MAP": "Permitir visualizar dados do lote no mapa"
    }
  },
  "COMPANIES": {
    "BIRTHDATE": "Data de Nascimento",
    "BIRTHDATE_COMAPANY": "Data de Fundação",
    "ZIP_CODE": "CEP",
    "NUMBER": "Número",
    "NAME": "Razão Social",
    "TAXPAYER": "CNPJ/CPF",
    "NEIGHBORHOOD": "Bairro",
    "INDEX_TITLE": "Companhias",
    "COMPLEMENT": "Complemento",
    "PUBLIC_PLACE": "Logradouro",
    "EMAIL_SIGNATURE": "Assinatura do email",
    "MSG": {
      "REQUIRED_DATA_CLIENT_TO_CREATE_RESERVE": "Exigir dados do cliente ao criar reserva"
    },
    "MODAL_TITLE": {
      "EDIT": "Editar Companhia",
      "ADD": "Adicionar Companhia"
    }
  },
  "CONFIG_WEBHOOK": {
    "INDEX_TITLE": "Configurações de Webhook",
    "VIEW": "Vizualizar Config. Webhook",
    "COMPANY": "Companhia",
    "SELLECT_A_COMPANY": "Selecione uma Companhia",
    "URL": "URL",
    "NOT_FUNCTIONALITIES": "Companhia não possui funcionalidades cadastradas",
    "CREATED": "Configuração criada com sucesso!",
    "ADD_CONFIG": "Adicionar Configuração",
    "URL_NOT_FUNCIONALITIES": "URL não possui funcionalidades vinculadas",
    "FIELD_MANDATORY": "Campo obrigatório",
    "FUNCTIONALITIES": {
      "INDEX_TITLE": "Funcionalidades",
      "FUNCTIONALITIE": "Funcionalidade",
      "HAS_WEBHOOK": "Possui Webhook",
      "CONTROLLER": "Controller",
      "LITERAL_NAME": "Nome Literal",
      "ACTIVE": "Ativa"
    }
  },
  "PANEL": {
    "WHATSAPP_TOKEN": "WhatsApp Token",
    "INFORM_DB_CONNECTION": "Adicionar ID Config. BD",
    "ID_CONFIG_BD": "ID Configuração BD ('-1' quando não há R2Loteamentos)",
    "INDEX_TITLE": "Painel Administrativo",
    "COMPANY": "Empresa",
    "COMPANY_NAME": "Nome da Empresa",
    "CONFIG_PLAN": "Configurações do plano",
    "FIELD_MAX_EXECEDED": "Valor inserido excede valor máximo",
    "PLAN": {
      "AMOUNT_MIN_THREE": "Valor mínimo 3.00",
      "NOT_FOUND": "Nenhum Plano foi encontrado",
      "TITLE": "Planos",
      "PLANS": "Planos",
      "PLAN": "Plano",
      "EMAIL_NOTIFICATIOS": "Qtd. Notificações Email",
      "MAX_LOTS_PERMITED": "Qtd. Máxima de Lotes",
      "WHATSAPP_NOTIFICATIONS": "Qtd. Notificações Whatsapp",
      "PLAN_NAME": "Nome do Plano",
      "MAX_SIZE_DRIVE": "Qtd. Armazenamento drive",
      "PLAN_EDIT": "Editar Plano",
      "PLAN_VIEW": "Visualizar Plano",
      "PLAN_CREATE": "Criar Plano",
      "FIELD_REQUIRED": "Campo obrigatorio",
      "PLAN_SUCCESSFUL_DELETED": "Plano deletado com sucesso",
      "PLAN_SUCCESSFUL_CREATED": "Plano criado com sucesso"
    }
  },
  "PROPERTY_TYPES": {
    "INDEX_TITLE": "Tipo de lotes",
    "PROPERTIES_TYPES": "Tipos de Propriedades",
    "MODAL_TITLE": {
      "EDIT": "Editar Tipo de Lote",
      "ADD": "Adicionar Tipo de Lote"
    }
  },
  "PAYMENT_METHODS": {
    "INDEX_TITLE": "Formas de Pagamento",
    "PAYMENTS_METHODS": "Formas de Pagamentos",
    "SHOW_ON_PROPOSAL": "Exibir na Proposta",
    "BILLET": "Gera Boletos",
    "CHARGE_EXPENSE": "Taxa de Cobrança",
    "ORDENATION": "Ordenação",
    "PAID_ON_DUE_DATE": "Baixa automática no Vencimento",
    "TAXABLE": "Tributável",
    "MODAL_TITLE": {
      "EDIT": "Editar Forma de Pagamento",
      "ADD": "Adicionar Forma de Pagamento"
    }
  },
  "MARITAL_STATUSES": {
    "INDEX_TITLE": "Estados Civis",
    "MARITAL_STATUSES": "Estados Civis",
    "SPOUSE_SIGNATURE_REQUIRED": "Requer assinatura de Cônjuge",
    "MODAL_TITLE": {
      "EDIT": "Editar Estado Civil",
      "ADD": "Adicionar Estado Civil"
    }
  },
  "ZOOP": {
    "SALES": {
      "VIEW_DETAILS": "Visualizar Detalhes"
    }
  },
  "LOTS": {
    "STATUS_FROM": "De Status",
    "STATUS_TO": "Para Status",
    "ALTER_STATUS_LOTS": "Alterar Status de Lotes",
    "IDEAL_FRACTION": "Fração Ideal",
    "IMPROVEMENTS": "Benfeitorias",
    "ONUS_DESCRIPTION": "Titulo do ônus",
    "ONUS": "Ônus",
    "PRICE_COUST": "Preço de custo",
    "LOT": "Lote",
    "DESCRIPTION": "Memorial Descritivo",
    "OBSERVATION": "Observações",
    "VIEW_REALTORS_RESERVES": "Visualizar reservas por corretores",
    "VALUE": "Valor",
    "RESERVES": "Reservas",
    "RESERVES_STATUS": "Status",
    "RESERVES_TYPE": "Tipo",
    "RESERVE_BROKE": "Corretor",
    "RESERVE_START": "Iniciado em",
    "RESERVE_FINISH": "Finalizado em",
    "CREATE_PROPOSE": "Criar Proposta",
    "APPLY": "Aplicar",
    "APPLY_IN": "Aplicar em",
    "WARNING_FIRE_ALLOTMENT_READJUSTMENT": "Reajuste será aplicado somente em propriedades que estejam Disponíveis e Bloqueadas, deseja continuar?",
    "NONE_READJUSTMENT_FOUND": "Nenhum Reajuste foi Encontrado",
    "PERCENTAGE": "Porcentagem",
    "READJUSTMENT_TITLE": "Reajustes",
    "VALUE_READJUSTMENT": "Aplicar Reajuste",
    "APPLY_VALUE_READJUSTMENT": "Aplicar Reajuste",
    "READJUSTMENT": "Reajuste de valor",
    "FIXED": "Reais",
    "FOOTAGE": "Metro",
    "BLOCK": "Quadra",
    "RESERVE": "Reservar",
    "INDEX_TITLE": "Lotes",
    "SITUATION": "Situação",
    "ALLOTMENT": "Loteamento",
    "DIMENSIONS": "Características",
    "FRONT_CONFRONTATION": "Logradouro",
    "BACKGROUND_CONFRONTATION": "Confrontação de Fundo",
    "RIGHT_CONFRONTATION": "Confrontação à Direita",
    "LEFT_CONFRONTATION": "Confrontação à Esquerda",
    "CHAMFER_CONFRONTATION": "Confrontação de Chanfro",
    "TOTAL_AREA": "Área",
    "METRAGEM_TOTAL": "Metragem total",
    "MEASURE_BACK": "Medida Fundo",
    "BACK_SIZE": "Tamanho do Fundo",
    "IMPORT_LOTS": "Importar Lotes",
    "MEASURE_FRONT": "Medida Frente",
    "MEASURE_LEFT": "Medida Esquerda",
    "FRONT_SIZE": "Tamanho da Frente",
    "MEASURE_RIGHT": "Medida Direita",
    "MEASURE_CHANFER": "Medida Chanfro",
    "CHAMFER_SIZE": "Tamanho do chanfro",
    "RIGHT_SIZE": "Tamanho do lado direito",
    "LEFT_SIZE": "Tamanho do lado esquerdo",
    "LOT_INFORMATION": "Informações do Lote",
    "SIMULATE_INSTALLMENT": "Simular Parcelamento",
    "ADDITIONAL_INFORMATION": "Informações Complementares",
    "REALTORS": "Corretores",
    "MODAL_TITLE": {
      "EDIT": "Editar Lote",
      "ADD": "Adicionar Lote",
      "VIEW": "Visualizar Lote",
      "SIMULATE": "Calcular Financiamento"
    }
  },
  "READJUSTMENT": {
    "APPLY_BY": "Reajustado por",
    "SELECT_BY": "Selecionar por",
    "TYPE": "Tipo",
    "PRICE_BEFORE": "Valor anterior",
    "PRICE_AFTER": "Valor aplicado",
    "TARGET": "Aplicado em",
    "TARGET_AB": "Aplicado",
    "APPLY_IN": "Aplicado em",
    "CREATED_AT": "Data do reajuste",
    "CREATED_AT_AB": "Criado em",
    "VALUE_OR_PERCENTAGE": "Valor/Porcentagem",
    "VALUE_OR_PERCENTAGE_AB": "Valor",
    "UNDO": "Desfazer reajuste",
    "UNDO_BY_LOT": "Desfazer por lote",
    "VIEW_READJUSTMENT": "Visualizar Reajuste"
  },
  "DASHBOARD": {
    "PAGE_TITLE": "Dashboard",
    "INITIAL_YEAR": "Ano Início",
    "FINAL_YEAR": "Ano Final",
    "PEOPLES": "Pessoas",
    "TOTAL_STORED": "Armazenamento utilizado",
    "TOTAL_STORED_AVAILABLE": "Armazenamento utilizado / Disponível",
    "STORE_AVAILABLE": "Armazenamento Disponivel"
  },
  "PROPERTIES": {
    "HAS_BUILDINGS": "Possuí Construções",
    "NAME": "Lote",
    "EXPORT_FILE_LOT": "Exportar lotes via arquivo",
    "PRICE": "Preço",
    "BLOCK": "Quadra",
    "LATITUDE": "Latitude",
    "LONGITUDE": "Longitude",
    "PROPERTY_TYPE": "Tipo",
    "DESCRIPTION": "Descrição",
    "TOTAL_AREA": "Área total",
    "NUMERO_REGISTRO": "Número de Registro",
    "INSCRICAO_CADASTRAL_IPTU": "Inscrição Cadastral - IPTU",
    "OWNER_NAME": "Nome do Proprietário",
    "PARCERIA_NAME": "Parceria",
    "BROKERAGE_VALUE": "Corretagem (R$)"
  },
  "SITUATIONS": {
    "TEXT_COLOR": "Cor do Texto",
    "INDEX_TITLE": "Situações de Lote",
    "BACKGROUND_COLOR": "Cor do Fundo"
  },
  "RESERVE": {
    "EDIT_RESERVE": "Editar Reserva",
    "TYPE": "Tipo de Reserva",
    "ADD_CLIENT_OR_CLEAR": "Por favor, adicione o cliente ou limpe o campo Pessoas",
    "ACTIVE": "Ativa",
    "HAS_PROPOSAL_APROVED": "Possui Proposta Aprovada",
    "REALTORS_RESERVE": "Reservas por Corretor",
    "ALLOTMENT": "Loteamento",
    "SELECT_ALLOTMENT": "Selecione um Loteamento",
    "STATUS": "Status",
    "CLIENT": "Cliente",
    "REALTOR": "Corretor",
    "DURATION": "Duração",
    "INDEX_TITLE": "Reservas",
    "RESPONSIBLE": "Responsável",
    "START_RESERVE": "Inicio da Reserva",
    "FINISH_RESERVE": "Final da Reserva",
    "CONFIRM_RESERVE": "Confirmar Reserva",
    "DROP_RESERVATION": "Derrubar Reserva",
    "RESERVE_DURATION": "Duração da Reserva",
    "CONFIRM_RESERVATION": "Confirmar Reserva",
    "CLIENT_ATTACHED_TO_RESERVE": "Cliente adicionado à reserva",
    "MODAL_TITLE": {
      "EDIT": "Editar Reserva",
      "ADD": "Adicionar Reserva"
    },
    "MSG": {
      "NONE_RECORD_FIND": "Nenhum Registro Encontrado",
      "REALLY_WHANT_DROP_THIS_RESERVE": "Realmente deseja derrubar esta reserva?"
    }
  },
  "RESERVE_TYPES": {
    "INDEX_TITLE": "Tipo de Reservas",
    "HAS_TIME_LIMIT": "Limite de Tempo",
    "MODAL_TITLE": {
      "EDIT": "Editar Tipo de Reservas",
      "ADD": "Adicionar Tipo de Reservas"
    }
  },
  "USERS": {
    "REAL_ESTATE_AGENCY": "Imobiliária",
    "IMAGE": "Imagem",
    "NOTIFY_ALLOWED": "Notificações ativadas com sucesso!",
    "NOTIFY_DENIED": "Notificações desativadas com sucesso!",
    "REALTORS": "Corretores",
    "COMPANIES": "Companhias",
    "INDEX_TITLE": "Usuários",
    "ADMS": "Administradores",
    "GRANT_ACCESS": "Conceder acesso",
    "ACCEPT_RECEIVE_NEWS_ABOUT_R2": "Deseja receber novidades da R2 Soft",
    "MODAL_TITLE": {
      "EDIT": "Editar Usuário",
      "ADD": "Adicionar Usuário",
      "CHANGE_MADE_SUCCESSFULLY": "Alteração realizada com sucesso!",
      "REALY_WHANT_DINY_ACCESS": "Deseja realmente negar acesso a esta companhia?",
      "REALY_WHANT_GRANT_ACCESS": "Deseja realmente conceder acesso a esta companhia?",
      "REALY_WHANT_GRANT_ACCESS_ALLOTMENT": "Deseja realmente conceder acesso a este loteamento?"
    }
  },
  "DRIVE_FILES_TYPE": {
    "INDEX": "Tipos de Anexo",
    "NAME": "Nome",
    "REQUIRE_ON_PERSON": "Requerido na Pessoa",
    "REQUIRED_PERSON": "Obrigatorio no cadastro da pessoa",
    "NONE_FOUND": "Nenhum Tipo de Anexo Cadastrado",
    "ADD_NEW": "Adicionar Novo Tipo de Anexo",
    "VIEW": "Visualizar Tipo de Anexo"
  },
  "CLIENTS": {
    "CRECI": "CRECI",
    "TYPE_ANEX": "Tipo de Anexo",
    "CLIENT": "Cliente",
    "IMPORT_FILE": "Importar pessoas via aquivo",
    "EXPORT_FILE_CLIENT": "Exportar pessoas via arquivo",
    "DOCUMENT": "Anexos",
    "CLIENT_IMPORT_WITH_SUCCESSS": "Pessoas Importadas com sucesso",
    "ADDRESS": "Endereço",
    "ADDRESS_COMPANY": "Endereço Comercial",
    "INDEX_TITLE": "Pessoas",
    "CREATE": "Adicionar Pessoas",
    "MOTHER_NAME": "Nome da mãe",
    "FATHER_NAME": "Nome do pai",
    "PROFESSION": "Profissão",
    "BIRTHDAY": "Data de nascimento",
    "BIRTHDAY_ABBREVIATED": "Data de Nasc.",
    "FOUNDATION_DAY": "Data de fundação",
    "TRADE_NAME": "Nome Fantasia",
    "BIRTH_PLACE": "Naturalidade",
    "NATIONALITY": "Nacionalidade",
    "DISPATCH_AGENCY": "Orgão expedidor",
    "DISPATCH_DATE": "Data da expedição",
    "MARITAL_STATUS": "Estado Civil",
    "MODAL_TITLE": {
      "EDIT": "Editar Pessoas",
      "ADD": "Adicionar Pessoas",
      "ADD_PHISICAL": "Adicionar Pessoas Física",
      "ADD_JURIDICAL": "Adicionar Pessoa Jurídica",
      "VIEW": "Visualizar Pessoas"
    },
    "DOCUMENTS": {
      "ACTIONS": "Ações",
      "NAME": "Nome",
      "ARCHIVE": "Arquivo",
      "DOCUMENT_NAME": "Nome do documento",
      "SIZE": "Tamanho",
      "DESCRIPTION": "Descrição",
      "DOWNLOAD": "Download",
      "DELET": "Deletar",
      "DELETE_SUCCESSFULL": "Registro deletado com sucesso.",
      "CREATED": "Documentos Anexados com Sucesso!",
      "ONLY_PDF": "Arquivo selecionado deve ser do tipo .pdf",
      "FILE_EMPTY": "Por favor, selecione um arquivo!",
      "VIEW": "Informações do documento",
      "UPLOADED_AT": "Data do upload",
      "NOT_FOUND": "Não existem documentos vinculados"
    }
  },
  "GENERAL": {
    "RESPONSABLE": "Responsavel",
    "CREATED_SUCCESSFULL": "Registro criado com sucesso",
    "UPDATED_SUCCESSFULL": "Registro Atualizado com sucesso.",
    "SELECT_ONE_ALLOTMENT": "Selecione um loteamento",
    "ID": "ID",
    "NO": "Não",
    "YES": "Sim",
    "NEW": "Novo",
    "NAME": "Nome",
    "TYPE": "Tipo",
    "GRUP": "Grupo",
    "CLEAR": "Limpar",
    "REAIS": "Reais",
    "SAVE": "Salvar",
    "NONE": "Nenhum",
    "LOGIN": "Login",
    "CONFRONTATIONS": "Confrontações",
    "MEASUREMENTS": "Medidas",
    "TITLE": "Geral",
    "SEX": "Sexo",
    "CRECI": "CRECI",
    "EMAIL": "E-mail",
    "TAXPAYER": "CNPJ/CPF",
    "PHONE": "Telefone",
    "PASSWORD": "Senha",
    "REMEMBER": "Lembrar",
    "WARNING": "Atenção",
    "SIMULATOR": "Simular",
    "COMMENT": "Observação",
    "WELCOME": "Bem-vindo(a)",
    "COMMENTS": "Observações",
    "PERCENTAGE": "Porcentagem",
    "PERMISSIONS": "Permissões",
    "NEW_PASSWORD": "Nova senha",
    "SEND_FILE": "Enviar arquivo",
    "INFO": "Informações",
    "CREATE_ACCOUNT": "Criar conta",
    "MORE_INFO": "Mais informações",
    "DOCUMENTATION": "Documentação",
    "BANK_DATAS": "Dados Bancários",
    "PHONE_TYPE": "Tipo de Telefone",
    "PERSONAL_DATA": "Dados Pessoais",
    "CURRENT_PASSWORD": "Senha atual",
    "NUMBER_RESERVE": "Número Reserva",
    "SELECT_FILE": "Selecionar Arquivo",
    "CONFIRM_PASSWORD": "Confirmar Senha",
    "RECOVER_PASSWORD": "Recuperar senha",
    "ALREADY_REGISTRED": "Já e cadastrado",
    "CREATE_YOUR_ACCOUNT": "Crie sua conta",
    "REQUIRED_FIELDS": "Campos Obrigatórios",
    "CONFIRM_NEW_PASSWORD": "Confirmar Nova Senha",
    "QUANTITY_MAX_RESERVE": "Quantidade Máxima de Reservas",
    "ADDED_BY": "Adicionado por",
    "EMPTY": "",
    "MSG": {
      "NOTIFY_CORRETORS_IMPORT": "Deseja notificar os corretores sobre o cadastro junto ao Loteamento",
      "CEP_INVALID": "CEP inválido",
      "CPF_INVALID": "CPF inválido",
      "PASSWORD_WEAK": "Senha fraca",
      "NOT_HAVE_ALL_DATA": "Preencha todos os dados para prosseguir",
      "INSERT_IN_ALL_FIELD_TO_NEXT": "Preencha todos os campos corretamente para prosseguir",
      "PASSWORD_MEDIUM": "Senha média",
      "PASSWORD_STRONG": "Senha forte",
      "DASHBOARD": "Acessar Dashboard",
      "EMAIL_INVALID": "E-mail inválido",
      "CHOOSE_A_CATEGORY_TO_FILE": "Por favor, selecione uma categoria para o arquivo ser enviado",
      "PHONE_INVALID": "Telefone inválido",
      "FIELD_LENGHT_MAX": "O Campo Descrição não pode conter mais que 200 caracteres",
      "CANOT_ENTER": "Não consegue entrar?",
      "REQUIRED_FIELD": "Campo obrigatório",
      "INVALID_MINUTES": "Minutos Inválidos",
      "TAXPAYER_INVALID": "CNPJ/CPF Inválido",
      "CRECI_INVALID": "Creci Inválido",
      "PASSWORD_TO_LONG": "Senha muito grande",
      "PASSWORD_TO_SMALL": "Senha muito curta",
      "EMAIL_REGISTED": "E-mail já cadastrado",
      "FORGET_PASSWORD": "Esqueci minha senha",
      "PLEASE_LOGIN": "Por favor faça o login",
      "PLEASE_INFORM_ALL_FIELDS_REQUIRED": "Por favor, preencha todos os campos obrigatorios",
      "COPY_PUBLIC_LINK": "Copiar link público",
      "INCORRECT_MINUTES": "Minutos Incorretos",
      "MINIMUM_CHARACTER": "Mínimo 2 caracteres",
      "SELECT_ONE": "Selecione {article} {name}",
      "DELETED_SUCCESS": "Deletado com sucesso",
      "UPDATE_SUCCESS": "Atualizado com sucesso",
      "SUCCESSFULLY_LOGGED": "Logado com sucesso",
      "IMPORT_FILE": "Importar lotes via arquivo",
      "MAXIMUM_CHARACTER": "Máximo 255 caracteres",
      "REGISTER_SUCCESS": "Cadastrado com sucesso",
      "BACK_TO_LOGIN": "Voltar à pagina de entrada",
      "NONE_LOT_REGISTER": "Nenhum Lote Cadastrado",
      "OR_CLICK_BELLOW": " ou click no botão abaixo",
      "ERROR_REGISTER": "Ocorreu um erro no cadastro",
      "ADD_ONE_PHONE": "Adicione ao menos um telefone",
      "MAX_DURATION_RESERVE": "Duração Máxima: {hours}",
      "NO_REGISTER": "Não Existem {name} Cadastrad{os}",
      "ERROR_ON_UPDATE": "Ocorreu um erro na atualização do cadastro",
      "DOWNLOAD_FILE_EXEMPLE": "Baixar arquivo exemplo",
      "DASHBOARD_CLIENTS": "Dashboard de Clientes",
      "PROGRESS_REGISTER_CLIENTS": "Progresso de Cadastro de Clientes",
      "REALLY_WANT_DELETE": "Realmente Deseja Excluir?",
      "REALLY_WANT_DUPLICATE": "Realmente Deseja Duplicar?",
      "REALLY_WANT_UPDATE": "Realmente Deseja Atualizar?",
      "REALLY_WANT_DELETE_NO_RETURN": "Realmente Deseja Excluir?\nNão será possível desfazer esta ação depois!",
      "DRAG_FILE_HERE": "Arraste e solte o arquivo aqui",
      "NONE_USER_FOUND": "Nenhum usuário foi encontrado",
      "NONE_PHONE_REGISTER": "Nenhum telefone cadastrado",
      "NONE_CHART_TO_DISPLAY": "Nenhum Gráfico para ser exibido",
      "NONE_CLIENT_FOUND": "Nenhuma Pessoa foi encontrado",
      "NONE_TEMPLATE": "Nenhum Template foi encontrado",
      "NONE_PANEL_FOUND": "Nenhuma Empresa foi Encontrada",
      "CONTINUE_WITH_GOOGLE": "Ou faça login com o Google",
      "GRUOP_UPDATED_SUCCESS": "Grupo alterado com sucesso",
      "NONE_COSTUMER_SELECTED": "Nenhum cliente selecionado",
      "NONE_COSTUMER_LIKED": "Nenhum cliente vinculado",
      "CLIENT_REQUIRED": "É obrigatório ao menos um cliente",
      "EMAIL_SENT_WITH_SUCCESS": "E-mail enviado com sucesso",
      "SELECT_ONE_COMPANY": "Selecione ao menos uma companhia",
      "DIFFERENT_PASSWORDS": "Senhas informadas são diferentes",
      "PASSWORD_DIFFERENT_FROM_THE_CURRENT": "A senha informada não corresponde à sua senha atual.",
      "ACCESS_DENIED_WITH_SUCCESS": "Acesso negado com sucesso",
      "RESERVE_DROP_SUCCESSED": "Reserva derrubada com sucesso",
      "LOTS_IMPORT_WITH_SUCCESSS": "Lotes Importados com sucesso",
      "MAX_MIN_PASSWORD": "Senha deve ter entre 8 e 20 caracteres",
      "ACCESS_GRANTED_WITH_SUCCESS": "Acesso concedido com sucesso",
      "RESERVE_CONFIRM_SUCCESSED": "Reserva confirmada com sucesso",
      "DUPLICATED_SUCCESSFUL": "Duplicado com Sucesso",
      "PLACEHOLDER_UPDATE_SUCCESS": "{name} Atualizada com sucesso",
      "USER_SUCCESSFULLY_REGISTERED": "Usuáro cadastrado com sucesso",
      "DONT_HAVE_ALLOTMENT": "Não existe nenhum loteamento cadastrado",
      "USERS_IMPORT_WITH_SUCCESSS": "Corretores Importados com sucesso",
      "SOME_TIME_TO_END_SESSION": "A sessão será encerrada em {time} minutos",
      "PLEASE_CHOOSE_YOUR_PASSWORD": "Finalizando o seu cadastro, escolha uma senha",
      "RESERVE_AMOUNT_UPDATED_SUCCESS": "Quantidade de reserva alterada com sucesso",
      "REQUIRE_ALLOTMENT_REGISTER_FIRST": "E necessário o cadastro do loteamento antes",
      "LOT_UPDATED_REMOTE": "O lote {name} da quadra {block} foi alterado.",
      "DONT_HAVE_ACCOUNT_WITH_THIS_EMAIL": "Não há uma conta associada à este e-mail",
      "ALLOTMENT_DONT_HAVE_LOTS": "Loteamento selecionado não possui lotes disponíveis",
      "FILL_THE_BOTTOM_DATA_TO_CHANGE_PASSWORD": "Preencha os dados para trocar a senha",
      "YOU_DONT_HAVE_PERMISSON": "Você não tem permissão para acesar essa funcionalidade",
      "PLEASE_ADD_A_PHONE_TO_GO_AFTER": "Por favor, adicione um telefone para prosseguir",
      "PLEASE_ADD_A_CLIENT_TO_GO_AFTER": "Por favor, adicione um cliente para prosseguir",
      "PLEASE_INFORM_ADDRESS_CLIENT_TO_CONTINUE": "Por favor, preencha os campos de endereço do cliente para continuar",
      "PLEASE_INFORM_ALL_MANDATORY_FIELDS": "Por favor, informe todos os campos obrigatorios",
      "PERMISSON_COMPANY_UPDATE_SUCCESS": "Permissões do Usuário na Companhia alteradas com sucesso!",
      "PERMISSON_ALLOTMENT_UPDATE_SUCCESS": "Permissões do Usuário no loteamento alteradas com sucesso!",
      "CONFIRM_REMOVE_PROPERTY_TYPE": "Tem certeza que realmente deseja remover esse tipo de propriedade?",
      "PREVIOUSLY_REGISTERED_CUSTOMER_INFORMATION": "Informações de cliente(s) já cadastrada(s) anteriormente",
      "SELECT_ONE_ALLOTMENT_IN_COMPANIES": "Selecione ao menos um loteamento na(s) companhia(s) selecionada(s)",
      "INFOR_YOUR_EMAIL_TO_GET_INFO_ABOUT_RESET_YOUR_PASSWORD": "Informe seu email e receba as informações para recuperar sua senha",
      "DONT_HAVE_RESERVE_TYPE_REGISTER": "Não há tipo de reserva cadastrado com limite de tempo. entre em contato com o administrador",
      "CLIENT_LOCATED_IN_THE_DATABASE": "Pessoa localizada na base de dados.",
      "CHECK_YOUR_DATA_AND_CONTINUE": "Confira os dados do cliente antes de continuar!",
      "CLIENT_ALREADY_IN_COMPANY": "Cliente já existe nesta empresa. \n Deseja atualiza-lo?",
      "CLIENT_ALREADY_IN_COMPANY_WITHOUT_UPDATE": "Essa pessoa já existe nesta empresa.",
      "DELETE_NOT_PERFORMED_BECAUSE_DEPENDENCY": "Operação não realizada. Existem registros vinculados a este ítem!",
      "DISPATCHING_AGENCY_INVALID": "Orgão Expedidor inválido",
      "INSERT_ADDRESS_TO_CONTINUE": "Insira um endereço para continuar",
      "DATE_INVALID": "Data inválida",
      "INSERT_DISPATCHING_AGENCY": "Insira Orgão Expedidor para continuar",
      "INSERT_NATIONALITY": "Insira nacionalidade para continuar",
      "INSERT_NATURALNESS": "Insira naturalidade para continuar",
      "INSERT_MOTHER_NAME": "Insira nome da mãe para continuar",
      "INSERT_FATHER_NAME": "Insira nome do pai para continuar",
      "INSERT_NAME": "Insira nome para continuar",
      "INSERT_STREET": "Insira logradouro para continuar",
      "INSERT_NUMBER": "Insira número no endereço para continuar",
      "INSERT_NEIGHBORHOOD": "Insira bairro para continuar",
      "CAMP_SEX_INVALID": "O campo sexo é inválido",
      "ACCESS_OTHER_PHONE_NUMBERS": "Clique em visualizar para acessar outros números!",
      "MAXIM_LIMIT_CHARACTER": "Valor excede o número máximo de caracteres"
    }
  },
  "TOUR": {
    "FINISH": "Finalizar",
    "COMPANY": "companhia",
    "THE_PHONE": "o telefone",
    "ALLOTMENT": "Loteamento",
    "THE_CLIENT": "o cliente",
    "THE_COMPANY": "a companhia",
    "THE_ALLOTMENT": "o loteamento",
    "RESERVE_TYPE": "tipo de reserva",
    "PROPERTY_TYPE": "tipo de propriedade",
    "THE_RESERVE_TYPE": "o tipo de reserva",
    "ADD_DESCRIPTION": "Adicione uma descrição",
    "THE_PROPERTY_TYPE": "o tipo de propriedade",
    "IF_WHANT_EDIT": "Caso queria editar {object}",
    "IF_WHANT_DELETE": "Caso queria apagar {object}",
    "TO_SAVE_CLICK_HERE": "Para salvar, clique aqui",
    "IF_WHANT_VIEW": "Caso queria viualizar {object}",
    "IF_WHANT_ADD_NEW": "Caso queria adicionar {object}",
    "THIS_SITUATION_NAME": "Descrição da situação de lote",
    "HAVE_TO_REGISTER_PHONE": "Deve cadastrar um telefone",
    "HAVE_TO_SELECT_PHONE_TYPE": "Deve selecionar um tipo de telefone",
    "HAVE_TO_INSERT_A_PHONE_NUMBER": "Deve inserir um número de telefone",
    "CLICK_HERE_TO_SET_THE_CLIENT": "Clique aqui para adicionar o cliente",
    "CASE_WHAT_IMPORT_LOTS": "Caso queira importar lotes </br> Clique aqui",
    "CASE_WHAT_VIEW_FULL_LOT": "Caso queria ver todas as informações do lote",
    "STATUS_USER_IN_THIS_COMPANY": "Status do usuário dentro dessa companhia",
    "FOR_MORE_OPTIONS_OF_THE_LOT": "Caso queria mais opções </br> Clique aqui",
    "STATUS_USER_IN_THIS_ALLOTMENT": "Status do usuário dentro desse loteamento",
    "IF_WHANT_CANCEL_CLICK_HERE": "Caso queira cancelar a ação </br> Clique aqui",
    "CASE_WHANT_SIMULATE_FEES": "Caso queria simular as parcelas </br> Clique aqui",
    "STATUS_GENERAL_USER": "Status geral do usuário, aplicado a todas as companhias",
    "CASE_WHANT_FILTER_RESERVE": "Caso queria filtrar as reservas </br> Clique aqui",
    "IF_WHANT_CHANGE_CLICK_HERE": "Caso queria modificar {object} </br> Clique aqui",
    "CASE_WHANT_RESERVE_THIS_LOT": "Caso queria reservar esse lote </br> Clique aqui",
    "CAMP_WHIT_OBRIGATORY": "Os campos obrigatórios são destacados com esse * vermelho",
    "CASE_WHANT_CHANGE_NAME_RESERVE_TYPE": "Caso queira mudar o nome do tipo da reserva",
    "CASE_WHANT_CHANGE_COLOR_RESERVE_TYPE": "Caso queira mudar a cor do tipo da reserva",
    "TO_IMPORT_FILES_IN_A_EXCEL": "Clique aqui para importar lotes por um arquivo execel",
    "MAX_RESERVE_USER_CAN": "Quantidade máxima de reservas que esse usuário pode realizar",
    "TO_DOWNLOAD_EXEMPLE_EXCEL_FILE": "Clique aqui para baixar um exemplo do arquivo execel",
    "CASE_WHAT_CHANGE_COLOR_BACKGROUND": "Clique para alterar a cor de fundo dessa situação",
    "CASE_WHANT_ADD_REALTORS": "Caso queira adicionar corretores em massa </br> Clique aqui",
    "IF_WHANT_ACESS_OPTIONS": "Caso queria acessar as opções do loteamento </br> Clique aqui",
    "CASE_WHAT_CHANGE_COLOR_OF_THE_TEXT": "Clique para alterar a cor da fonte dessa situação",
    "COPY_LINK_TO_RESERVE_PUBLIC": "Copiar link para cadastro de reservas no ambiente publico",
    "ADD_COLOR_TO_REPRESENT_RESERVE_TYPE": "Adicione uma cor para representar o tipo de reserva",
    "HERE_CAN_CHOSE_HOW_USER_GROUP": "Aqui faz a escolha de qual gropo esse usuário vai pertencer",
    "CLICK_HERE_TO_SHOW_MORE_INFO_USER": "Clique aqui para mostrar mais informações sobre o usuário",
    "THIS_HIS_THE_LOT_AVAILABLE": "Este e um lote disponivel </br> para mais informações clique aqui",
    "CLICK_TO_DOWNLOAD_EXEMPLE": "Clique para baixar o arquivo de importação de corretores em branco",
    "CASE_WHANT_CLOSE_MORE_INFO_CLICK_HERE": "Caso queira fechar o mais informações </br> Clique aqui",
    "CLICK_HERE_TO_GRANT_ACESS_TO_THIS_ALLOTMENT": "Clique aqui para conceder acesso a esse loteamento",
    "EXEMPLE_HOW_TO_LOOK_TYPE_RESERVE": "Exemplo de como fica uma reserva quando tem esse tipo de reserva",
    "TO_NEXT_ABA_CLICK_HERE": "Após o preenchimento dos campos </br> Clique aqui para ir para proxima aba",
    "CASE_WHANT_VIEW_MORE_INFO_CLIENT": "Caso queira ver mais informações sobre um client </br> Clique aqui",
    "INFO_ABOUT_USER_GROUP_CAN_CHANGE": "Informa o grupo de usuário o mesmo esta </br> podendo ser alterado clicando aqui",
    "RESERVE_TYPE_HAVE_TIME_LIMIT": "Se o tipo de reserva tem ou não limite de tempo </br> Apenas um tipo pode ter esse limite",
    "EACH_THIS_BLOCKS_IS_ONE_BLOCK_YOU_CAN_CLOSE": "Cada um desses blocos representa uma quadra </br> Podendo ser fechado clicando aqui",
    "CLICK_HERE_OUR_IN_COMPANY_NAME_TO_SHOW_ALL_ALLOTMENTS_OF_THE_COMPANY": "Clique aqui ou no nome da companhia para mostrar todos os loteamentos disponiveis dessa companhia",
    "HERE_YOU_CAN_CHOSE_HOW_MANY_RESERVES_THIS_USER_CAN_DO": "Este campo serve para escolher a quantidade de reservas esse usuário poderar realizar </br> Limite so aplicado a usuários não adiministradores"
  }
}
